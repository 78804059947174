import React, { useState, useEffect } from 'react';
import loadingIcon from '../../assets/images/loading.gif';
import APIClient from 'lib/APIClient';

const AddCandidateToSequenceModal = ({ isOpen, onRequestClose, candidateId, authToken }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sequences, setSequences] = useState([]);
  const [selectedSequenceId, setSelectedSequenceId] = useState(null)

  useEffect(() => {
    if (candidateId) {
      fetchSequences();
    }
  }, []);

  useEffect(() => {
    setError(null);
  }, [selectedSequenceId, candidateId])

  const fetchSequences = async () => {
    try {
      if (candidateId) {
        setLoading(true);
        const response = await APIClient.fetchSequences(authToken);
        setSequences(response);
        setSelectedSequenceId(response[0].id)
      }
    } catch (err) {
      setError('Failed to fetch sequences');
    } finally {
      setLoading(false);
    }
  }      
  
  if (!candidateId || !isOpen) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await APIClient.addCandidateToSequence(authToken, selectedSequenceId, candidateId);
      if (response.error) {
        setError(response.error)
      } else {
        onRequestClose();
      }
    } catch (err) {
      setError('Failed to add candidate to sequence');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="popup-v2">
      <div className="popup-v2__overlay"
           onClick={onRequestClose}>
      </div>

      <div className="popup-v2__popup">
        <form>
          <div className="popup-v2__popup__header">
            <div className="popup-v2__popup__header__title">
              Add to Sequence
            </div>

            <div className="popup-v2__popup__header__description">
              Add this candidate into a created sequence
            </div>

            { error && <div className="popup-v2__popup__header__error">{error}</div> }
          </div>

          <div className="popup-v2__popup__content">
            <div className="form-v2">
              <div className="form-v2__field">
                <label htmlFor="sequence-name">Sequence name</label>

                <div className="select-v2">
                  <select value={selectedSequenceId || ''}
                          onChange={(event) => setSelectedSequenceId(event.target.value)}
                          id="project-id">
                    {sequences.map((sequence, index) => {
                      return (
                        <option key={index} value={sequence.id}>{sequence.title}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-v2__popup__footer">
            <button onClick={onRequestClose}
                    type='button'
                    className="button-v2 button-v2--secondary">Cancel</button>

            <button onClick={handleSubmit}
                    type='submit'
                    disabled={!selectedSequenceId || loading}
                    className={["button-v2", selectedSequenceId && !loading ? "" : "button-v2--disabled"].join(" ")}>Add</button>
            {loading && (
              <div className="loading-container">
                <img src={loadingIcon} className="loading-icon"/>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCandidateToSequenceModal;
