import React from 'react';
import StyleButton from '../StyleButton';
import InjectionButton from '../InjectionButton';
import AddLinkButton from '../AddLinkButton';

const Header = ({ handleSave, toggleInlineStyle, injectPlaceholder, addLink, editorState }) => {

  const inlineStyles = [
    { type: 'BOLD', label: 'B' },
    { type: 'ITALIC', label: 'I' },
    { type: 'UNDERLINE', label: 'U' }
  ];

  const injectionPlaceholders = [
    { label: 'first name', value: '{{ first_name }}' },
  ]

  const isStyleActive = (styleType) => editorState.getCurrentInlineStyle().has(styleType);

  return (
    <div className="text-editor__header">
      <div className='text-editor__styles-container'>
        {inlineStyles.map((style, index) => (
          <StyleButton
            key={index}
            style={style}
            toggleStyle={toggleInlineStyle}
            isActive={isStyleActive(style.type)}
          />
        ))}
        {injectionPlaceholders.map((object, index) => (
          <InjectionButton 
            key={index}
            label={object.label}
            value={object.value}
            injectPlaceholder={injectPlaceholder}
          />
        ))}
        <AddLinkButton addLink={addLink} />
      </div>
      <div className='text-editor__save'>
        <button onClick={handleSave}>SAVE</button>
      </div>
    </div>
  );
}

export default Header;
