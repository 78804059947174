import React from 'react';
import clockIcon from '../../../assets/images/clock.svg';
import gearButtonIcon from '../../../assets/images/gearButton.svg';

const StepSettingsBar = ({ step, onSettingsClick }) => {
  const waitPeriod = step.order === 0 && [0, null].includes(step.waitDays) 
    ? '10 MINUTES' 
    : `${step.waitDays || 1} ${step?.waitDays > 1 ? 'DAYS' : 'DAY'}`;

  const message = step.order === 0 
    ? `AUTOMATICALLY SENDS ${waitPeriod} AFTER CONTACT IS ADDED` 
    : `AUTOMATICALLY SENDS ${waitPeriod} AFTER`;

  return (
    <div className='steps-list__settings-container'>
      <div className='steps-list__settings__message'>
        <img src={clockIcon} className='clock-icon' />
        { message }
      </div>
      { step.id && <img src={gearButtonIcon} onClick={onSettingsClick} className='settings-popup-button'/> }
    </div>
  )
}

export default StepSettingsBar;
