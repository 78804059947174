import React, { useState, useEffect } from "react";
import greyEmailIcon from '../../assets/images/email_grey.svg';
import yellowEmailIcon from '../../assets/images/email_yellow.svg';

import APIClient from "lib/APIClient";
import CopyToClipboard from "components/CopyToClipboard";
import InsufficientEmailCreditsNotice from "./errors/InsufficientEmailCreditsNotice";

export default function({ authToken, candidateId, searchId, hasValidEmail }) {
  const [requested, setRequested] = useState(false);
  const [displayEmailCreditsNotice, setDisplayEmailCreditsNotice] = useState(false);
  const [emailHover, setEmailHover] = useState(false);
  const [contactInformation, setContactInformation] = useState({ email: null });
  const [validEmailFound, setValidEmailFound] = useState(hasValidEmail);

  useEffect(() => {
    async function fetchEmailInfo() {
      try {
        const { discoverable } = await APIClient.getCandidateEmailDiscoverableInformation(authToken, candidateId);
  
        if (discoverable === false) {
          setDisplayEmailCreditsNotice(true);
        }
      } catch (error) {
        console.error('Failed to fetch email info:', error);
      }
    }

    fetchEmailInfo();
  }, [authToken, candidateId]); 

  const shouldDisplayEmailCreditsNotice =
  (!requested && emailHover && displayEmailCreditsNotice) ||
  (requested && displayEmailCreditsNotice);

  async function handleContactClick(event) {
    event.preventDefault();

    const projectId = getProjectId();

    const { email, canDisplayEmail } = await APIClient.getCandidateContactInformation(authToken, candidateId, searchId, projectId);

    if (!canDisplayEmail) {
      setDisplayEmailCreditsNotice(true);
      setRequested(true);
      return;
    }

    setContactInformation({
      email: email
    });

    setRequested(true);

    if (email !== "") {
      const event = new CustomEvent("validEmailFound", { detail: { candidateId: candidateId } });

      window.dispatchEvent(event);
    };
  };

  useEffect(() => {
    window.addEventListener("validEmailFound", (event) => {
      if (event.detail.candidateId == candidateId) {
        setValidEmailFound(true);
      };
    });
  }, []);

  const getProjectId = () => {
    const pathArray = window.location.pathname.split('/');
    const projectIndex = pathArray.indexOf('projects');
    let projectId = null;
    if (projectIndex !== -1 && pathArray.length > projectIndex + 1) {
        projectId = pathArray[projectIndex + 1];
    }

    return projectId;
  }

  return requested && (contactInformation.email || contactInformation.email == '') ? (
    <>
      <div className="attribute search">
        <img src={contactInformation.email !== "" ? yellowEmailIcon : greyEmailIcon} className="contact__svg" />

        {contactInformation.email !== "" ? (
          <CopyToClipboard body={contactInformation.email} />
        ) : (
          "N/A"
        )}
      </div>
    </>
  ) : (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div className="attribute search">
        <img src={validEmailFound ? yellowEmailIcon : greyEmailIcon} className="contact__svg" />

        {!displayEmailCreditsNotice ? (
          <a onClick={handleContactClick}>Show email</a>
        ) : (
          <a onMouseEnter={() => setEmailHover(true)}
             onMouseLeave={() => setEmailHover(false)}
             style={{color: '#747474'}}>
            Show email
          </a>
        )}
      </div>

      {shouldDisplayEmailCreditsNotice && (
        <div className='email-error__container'>
          <InsufficientEmailCreditsNotice />
        </div>
      )}
    </div>
  );
};
