import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import SelectOption from './SelectOption';
import { CustomValueContainer } from './CustomValueContainer';
import CreateProjectButton from './CreateProjectButton';
import APIClient from "lib/APIClient";

const options = [
  { value: 'genderDiverse', label: 'Gender-diverse' },
  { value: 'ethnicallyDiverse', label: 'Ethnically-diverse' },
  { value: 'veteran', label: 'Veterans' },
  { value: 'hasDisability', label: 'People with disabilities' },
];

export default function DiversityProjectCreator({ 
  projectAnalyzing, 
  jobId, 
  projectId, 
  authToken,
  searchPath
}) {
  const [creatingProject, setCreatingProject] = useState(projectAnalyzing || false);
  const [projectCreated, setProjectCreated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [projectSearchPath, setProjectSearchPath] = useState(searchPath || null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const wrapperRef = useRef(null); 

  const handleChange = selectedOptions => {
    setSelectedOptions(selectedOptions);
  };

  const handleProjectSearchClick = () => {
    // this is the only way we can get react components to render on page load.
    if (projectSearchPath) {
      window.location.href = projectSearchPath;
    }
  }

  const handleSubmit = () => {
    setCreatingProject(true);
    const diversityFilters = selectedOptions.reduce((acc, option) => {
      acc[option.value] = true;
      return acc;
    }, {});

    APIClient.createProjectFromJob(authToken, jobId, diversityFilters)
      .then(response => {
        setCreatingProject(false);
        setProjectCreated(true);
        setProjectSearchPath(response.search_path);
      })
      .catch(error => {
        setCreatingProject(false);
      });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    projectSearchPath ? (
      <button onClick={handleProjectSearchClick} className='create-project-button'>Go to Project</button>
    ) : (
      <div ref={wrapperRef} className='diversity-project-creator__container'>
        <Select
          options={options}
          isMulti
          isClearable={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          menuIsOpen={menuIsOpen}
          components={{
            Option: SelectOption,
            ValueContainer: CustomValueContainer,
          }}
          onChange={handleChange}
          value={selectedOptions}
          className="diversity-project-creator-select__container"
          classNamePrefix="react-select"
          placeholder="Search Criteria"
        />
        <CreateProjectButton projectSearchPath={projectSearchPath} creatingProject={creatingProject} handleSubmit={handleSubmit} />
      </div>
    )
  );
}
