import React from 'react';
import { components } from 'react-select';

export const CustomValueContainer = ({ children, getValue, ...props }) => {
  const value = getValue();
  let labelToDisplay = '';
  
  if (value.length > 1) {
    labelToDisplay = "Multiple selected";
  } else if (value.length === 1) {
    labelToDisplay = value[0].label;
  }

  return (
    <components.ValueContainer {...props}>
      {!value.length ? children : labelToDisplay}
    </components.ValueContainer>
  );
};
