import React, { useState } from "react";

const ProjectStatus = ({ status }) => {
  return (
    <span className="project-status">
      { status === 'completed' && 'Project Completed' }
      { status === 'archived' && 'Project Archived' }
    </span>
  );
};

export default ProjectStatus;
