import React, { useState } from "react";
import APIClient from "lib/APIClient";
import loadingIcon from '../../assets/images/loading.gif';

const AddSequencePopup = ({ authToken, projects, visible, onExit }) => {
  const [sequenceName, setSequenceName] = useState("");
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(projects[0].id);

  const handleCreate = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    const response = await APIClient.importProjectToSequence(
      authToken,
      projectId,
      sequenceName
    );

    setLoading(false);
    
    if (response.status === 200) {
      window.location.reload();
    } else {
      setError('There was an error creating the sequence')
    }
  }

  return visible ? (
    <div className="popup-v2">
      <div className="popup-v2__overlay"
           onClick={onExit}>
      </div>

      <div className="popup-v2__popup">
        <form>
          <div className="popup-v2__popup__header">
            <div className="popup-v2__popup__header__title">
              Add Sequence
            </div>

            <div className="popup-v2__popup__header__description">
              Build a new email sequence to engage your prospects
            </div>

            { error && <div className="popup-v2__popup__header__error">{error}</div> }
          </div>

          <div className="popup-v2__popup__content">
            <div className="form-v2">
              <div className="form-v2__field">
                <label htmlFor="sequence-name">Sequence name</label>

                <input type="text"
                       value={sequenceName}
                       onChange={(event) => setSequenceName(event.target.value)}
                       id="sequence-name" />
              </div>

              <div className="form-v2__field">
                <label htmlFor="project-id">Import candidates</label>

                <div className="form-v2__field__description">Choose a project to import saved candidates from</div>

                <div className="select-v2">
                  <select value={projectId}
                          onChange={(event) => setProjectId(event.target.value)}
                          id="project-id">
                    {projects.map((project, index) => {
                      return (
                        <option key={index} value={project.id}>{project.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-v2__popup__footer">
            <button onClick={onExit}
                    type='button'
                    className="button-v2 button-v2--secondary">Cancel</button>

            <button onClick={handleCreate}
                    type='submit'
                    disabled={!sequenceName || loading}
                    className={["button-v2", sequenceName && !loading ? "" : "button-v2--disabled"].join(" ")}>Create</button>
            
            {loading && (
              <div className="loading-container">
                <img src={loadingIcon} className="loading-icon"/>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddSequencePopup;
