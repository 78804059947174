import React from 'react';

const ToggleRadioInput = ({ name, value, checked, label, canToggle, onChange }) => (
  <div onClick={onChange}>
    <input
      type="radio"
      readOnly
      name={name}
      value={value}
      autoComplete="off"
      checked={checked}
    />
    <label htmlFor={name} className={`${canToggle && checked && 'active'}`}>
      {label}
    </label>
  </div>
);

export default ToggleRadioInput;
