import useDidMountEffect from "hooks/useDidMountEffect";

// Triggers a form submission 
export const instantiateLiveFormApply = (trigger) => {
  useDidMountEffect(() => {
    const event = new Event("liveFormController.apply");
    
    document.dispatchEvent(event);
  }, [trigger]);
}
