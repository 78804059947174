import React, { useState } from 'react';

const SelectOption = props => {
  return (
    <div {...props.innerProps} className='option' >
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        onClick={() => {
          props.selectOption(props.data);
        }}
      />{" "}
      <label onClick={() => props.selectOption(props.data)}>{props.label}</label>
    </div>
  );
};

export default SelectOption;
