import React, { useState, useRef } from 'react';
import StepEmailTemplateEditor from './stepEmailTemplateEditor';
import StepPreview from './stepPreview';
import APIClient from '../../lib/APIClient';
import { convertKeysToCamelCase } from '../../helpers/base_helper';
import StepSettingsPopup from './stepSettingsPopup';
import StepSettingsBar from './StepSettingsBar';

const StepsContainer = (props) => {
  const { steps, sequence, authToken } = props;
  const editorRef = useRef(null);
  const newDraft = { id: null, subject: "", body: "" };
  const [currentSteps, setCurrentSteps] = useState(steps.length > 0 ? steps : [{...newDraft, order: 0, waitDays: 0}]);
  const [selectedStep, setSelectedStep] = useState(currentSteps[0]);
  const [stepSettingsOpen, setStepSettingsOpen] = useState(false);

  const handleAddStepClick = () => {
    setCurrentSteps([...currentSteps, newDraft]);
    setSelectedStep(newDraft);
  };

  const handleStepClick = (step) => {
    setSelectedStep(step);
  };

  const handleStepSettingsClick = (step) => {
    setSelectedStep(step);
    setStepSettingsOpen(true);
  };

  const closeStepSettings = () => {
    setStepSettingsOpen(false);
  };

  const handleDeleteStep = async (step) => {
    try {
      await APIClient.deleteStep(authToken, step.id, sequence.id);
      const updatedSteps = await fetchAndSetSteps();
      setCurrentSteps(updatedSteps);
    } catch(error) {
      console.error('Error updating step settings:', error);
    }
  }

  const handleSaveSettings = async (daysToWait) => {
    try {
      await APIClient.updateStepSettings(authToken, selectedStep.id, sequence.id, daysToWait);
      const updatedSteps = await fetchAndSetSteps();
      setCurrentSteps(updatedSteps);
      closeStepSettings();
    } catch (error) {
      console.error('Error updating step settings:', error);
    }
  };

  const fetchAndSetSteps = async () => {
    try {
      const stepsResponse = await APIClient.requestSequenceSteps(authToken, sequence.id);
      const camelCaseSteps = convertKeysToCamelCase(stepsResponse);
      setCurrentSteps(camelCaseSteps);
      return camelCaseSteps;
    } catch (error) {
      console.error('Error fetching steps:', error);
    }
  };

  return (
    <div className="steps-container" ref={editorRef}>
      <div className="steps-list">
        {currentSteps.map((step, index) => (
          <div className='steps-list__step' key={index}>
            <StepSettingsBar step={step} onSettingsClick={() => handleStepSettingsClick(step)} />
            <div 
              className={`steps-list__step-item ${selectedStep === step ? 'selected' : ''}`} 
              onClick={() => handleStepClick(step)}>
              <StepPreview step={step} onDeleteStep={() => handleDeleteStep(step)}/>
            </div>
          </div>
        ))}
        <button className="button-v2 steps-list__add-step-button" onClick={handleAddStepClick}>Add Step</button>
      </div>
      <div className="step-editor">
        <StepEmailTemplateEditor
          emailTemplateId={selectedStep?.emailTemplate?.id}
          initialSubject={selectedStep?.subject}
          initialBody={selectedStep?.formattedBody || selectedStep?.plainTextBody}
          initialHTMLBody={selectedStep?.htmlBody}
          authToken={authToken}
          sequenceId={sequence.id}
          selectedStepId={selectedStep?.id}
          setCurrentSteps={setCurrentSteps}
          setSelectedStep={setSelectedStep}
        />
      </div>
      <StepSettingsPopup
        step={selectedStep}
        visible={stepSettingsOpen}
        onExit={closeStepSettings}
        handleSaveSettings={handleSaveSettings}
      />
    </div>
  );
};

export default StepsContainer;
