import React from 'react';
import APIClient from 'lib/APIClient';
import emailPromptIcon from '../../assets/images/generate_email_prompt_icon.svg';

const CreateEmailPromptIcon = ({ authToken, candidateId, projectId }) => {
  const handleClick = (event) => {
    event.stopPropagation();

    const customEvent = new CustomEvent('openModalWithCandidate', { detail: { candidateId, projectId } });
    window.dispatchEvent(customEvent);
  }

  return (
    <div class='attribute search'>
      <img src={emailPromptIcon} className="contact__svg" onClick={handleClick} />
    </div>
  );
}

export default CreateEmailPromptIcon;
