import React, { useState, useEffect, useRef } from 'react';
import APIClient from "lib/APIClient";
import loadingIcon from '../../assets/images/loading.gif';
import uploadIcon from '../../assets/images/upload_icon.svg';

const RankingPage = ({ job, authToken, hasOauthCredential }) => {
  const [jobCandidates, setJobCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);


  useEffect(() => {
    checkProcessingStatus();
  }, []);

  useEffect(() => {
    let interval;
    
    if (loading) {
      interval = setInterval(checkProcessingStatus, 5000);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleAddToSequence = (e, candidateId) => {
    if (hasOauthCredential) {
      window.openAddCandidateToSequenceModal(e, candidateId, authToken);
    } else {
      window.location.href = '/sequences';
    }
  };

  const fetchJobCandidates = async () => {
    try {
      const response = await APIClient.getJobCandidates(job.id, authToken);
      if (response.ok) {
        const data = await response.json();
        setJobCandidates(data);
      }
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const checkProcessingStatus = async () => {
    try {
      const response = await APIClient.getJobStatus(job.id, authToken);
      if (response.ok) {
        const data = await response.json();
        await fetchJobCandidates();
        if (data.processing) {
          setLoading(true);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error checking status:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length > 250) {
      alert('You can only upload a maximum of 250 PDFs.');
      e.target.value = '';
    } else {
      handleSubmit(e, selectedFiles);
    }
  };

  const handleUploadClick = () => {
    if (!loading && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveCandidate = async (event, jobCandidateId) => {
    event.stopPropagation();

    try {
      const response = await APIClient.removeCandidateFromJob(job.id, jobCandidateId, authToken);
      
      if (response.status === 200) {
        alert('Candidate removed successfully.');
        setJobCandidates(jobCandidates.filter(jobCandidate => jobCandidate.id !== jobCandidateId));
      } else {
        alert('Failed to remove candidate.');
      }
    } catch (error) {
      console.error('Error removing candidate:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleSubmit = async (e, selectedFiles) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      alert('Please select files to upload.');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('resumes[]', selectedFiles[i]);
    }

    try {
      setLoading(true);
      const response = await APIClient.uploadCandidatesToJob(job.id, formData, authToken);
      if (response.ok) {
        fileInputRef.current.value = '';
      } else {
        alert('Failed to upload resumes. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className='ranking-page-container'>
      <section className='upload-box-container'>
        <div className='upload-box-container__content-title'>Attach resumes to rank</div>
        <div className={`upload-box ${loading ? 'disabled' : ''}`} onClick={handleUploadClick}>
          <img src={uploadIcon} className='upload-box__upload-icon' alt='upload' />
          <label htmlFor='resume-upload' className='upload-box__description'>Select resume PDF'S. (250 PDF'S MAX)</label>
          <input
            type='file'
            accept='application/pdf'
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
            disabled={loading}
          />
        </div>
      </section>

      <div id="addCandidateToSequenceModal"></div>

      {loading && (
        <div className='loading-container'>
          <img src={loadingIcon} alt='loading' className='loading-icon' />
          <p>Ranking Attached Resumes</p>
        </div>
      )}

      {jobCandidates.length > 0 && (
        <div className='candidates-container'>
          <table className='candidate-table'>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Candidate Name</th>
                <th>Resume</th>
                <th>Add to Sequence</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jobCandidates.map((jobCandidate, index) => (
                <tr key={jobCandidate.id}>
                  <td>{index + 1}</td>
                  <td>{jobCandidate.candidate.full_name}</td>
                  <td>
                    <a
                      onClick={() => window.open(`/candidates/${jobCandidate.candidate.id}/download_resume`, '_blank')}
                    >
                      View resume
                    </a>
                  </td>
                  <td><a href='#' className={`${jobCandidate.candidate.email ? '' : 'disabled'}`} onClick={(e) => handleAddToSequence(e, jobCandidate.candidate.id)}>Add to sequence</a></td>
                  <td>{jobCandidate.candidate.email ? <p className='email'>{jobCandidate.candidate.email}</p> : <p className='disabled'>No email</p>}</td>
                  <td><a href='#' onClick={(event) => handleRemoveCandidate(event, jobCandidate.id)}>Remove candidate</a></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RankingPage;
