import React, { useState } from 'react';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';

const BoardFilterDrawer = ({ boardReadyOption, boardOfDirectorsOption, boardOfAdvisorsOption, chiefOfficerOption }) => {  
  const [boardReady, setBoardReadyOnly] = useState(boardReadyOption);
  const [boardOfDirectors, setBoardOfDirectors] = useState(boardOfDirectorsOption);
  const [boardOfAdvisors, setBoardOfAdvisors] = useState(boardOfAdvisorsOption);
  const [chiefOfficer, setChiefOfficer] = useState(chiefOfficerOption);

  instantiateLiveFormApply(boardReady);
  instantiateLiveFormApply(boardOfDirectors);
  instantiateLiveFormApply(boardOfAdvisors);
  instantiateLiveFormApply(chiefOfficer);

  const toggleBoardReady = () => {
    const active = document.getElementById('board-ready-toggle').checked === true
    setBoardReadyOnly(!active);
    setBoardOfDirectors(!active);
    setBoardOfAdvisors(!active);
    setChiefOfficer(!active);
  }

  return (
    <>
      <div className="toggle">
        <input type="checkbox"
               readOnly
               id='board-ready-toggle'
               name='board_ready'
               value={boardReady}
               checked={boardOfDirectors || boardOfAdvisors || chiefOfficer || boardReady }
               autoComplete="off" />

        <label checked="checked" htmlFor="board_ready">
          <div className="toggle__slider" onClick={ e => toggleBoardReady() }></div>

          Show board-ready candidates
        </label>
      </div>
      <ul>
        <li className="filter filter-list-item">
          <label className="checkbox" checked="checked" htmlFor="board_of_directors">
            <input type="checkbox"
                  name='board_of_directors'
                  value={boardOfDirectors}
                  checked={boardOfDirectors}
                  onChange={() => setBoardOfDirectors(!boardOfDirectors)}
                  autoComplete="off" />
            Board of Directors
          </label>
        </li>
        <li>
          <label className="checkbox" checked="checked" htmlFor="board_of_advisors">
            <input type="checkbox"
                  readOnly
                  name='board_of_advisors'
                  value={boardOfAdvisors}
                  checked={boardOfAdvisors}
                  onChange={() => setBoardOfAdvisors(!boardOfAdvisors)}
                  autoComplete="off" />
            Board of Advisors
          </label>
        </li>
        <li>
          <label className="checkbox" checked="checked" htmlFor="chief_officer">
            <input type="checkbox"
                   readOnly
                   name='chief_officer'
                   value={chiefOfficer}
                   checked={chiefOfficer}
                   onChange={() => setChiefOfficer(!chiefOfficer)}
                   autoComplete="off" />
            Chief Officers
          </label>
        </li>
      </ul>
    </>
  )
}

export default BoardFilterDrawer;
