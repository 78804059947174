import React, { useState } from 'react';
import DoubleToggleSlider from './DoubleToggleSlider';

const DiversityLogicToggle = ({ selectedDiversityFiltersCount, diversityFilterLogic }) => {
  const canToggle = selectedDiversityFiltersCount > 1;
  const [selectedValue, setSelectedValue] = useState(selectedDiversityFiltersCount > 1 && diversityFilterLogic ? diversityFilterLogic : "AND");

  const canToggleSlider = () => {
    return selectedDiversityFiltersCount > 1;
  }

  const toggleSlider = (value) => {
    if (!canToggleSlider()) return;

    setSelectedValue(value);
  }

  const toggleOptions = [
    { value: 'AND', label: 'And' },
    { value: 'OR', label: 'Or' }
  ]

  return (
    <DoubleToggleSlider
      selectedValue={selectedValue}
      inputName="diversity_filter_logic"
      onToggle={toggleSlider}
      toggleOptions={toggleOptions}
      canToggle={canToggle}
    />
  )
};

export default DiversityLogicToggle;
