import React from 'react';
import Header from './header';
import BodyEditor from './BodyEditor';
import SubjectEditor from './SubjectEditor';
import useEditor from '../useEditor';

const EmailEditor = (props) => {
  const { 
    subject, 
    handleSubjectChange,
    body, 
    setBody,
    handleSave
  } = props;

  const { editorRef, 
    editorState, 
    onChange, 
    handleKeyCommand, 
    toggleInlineStyle,
    injectPlaceholder,
    addLink } = useEditor(body, setBody);

  return (
    <div>
      <Header 
        handleSave={handleSave}
        toggleInlineStyle={toggleInlineStyle} 
        injectPlaceholder={injectPlaceholder}
        addLink={addLink} 
        editorState={editorState}
      />
      <SubjectEditor subject={subject} handleSubjectChange={handleSubjectChange} />
      <BodyEditor 
        editorRef={editorRef} 
        editorState={editorState} 
        onChange={onChange} 
        handleKeyCommand={handleKeyCommand}  
      />
    </div>
  )
};

export default EmailEditor;
