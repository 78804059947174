import React, {
  useRef,
  useState
} from "react";
import copyIcon from '../../assets/images/copy_icon.svg';
import copy from "copy-to-clipboard";

export default function({ body }) {
  const inputRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  function handleTextClick(event) {
    event.stopPropagation();
    inputRef.current.select();

    copy(body);
    triggerPopup();
  };

  function handleCopyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    inputRef.current.select();

    copy(body);
    triggerPopup();
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 800);
  }

  return (
    <div className="copy-to-clipboard">
      <input type="text"
             value={body.length > 18 ? `${body.slice(0, 16)}...` : body}
             onClick={handleTextClick}
             ref={inputRef} />

      <img onClick={handleCopyClick} src={copyIcon} />
      
      <div className={`copied-to-clipboard__popup ${showPopup ? '' : ' copied-to-clipboard__popup--hidden'}`}>
        <b>Copied!</b>
        <p>{body}</p>
      </div>
      
    </div>
  );
};
