import React from 'react';
import ToggleRadioInput from './ToggleRadioInput';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';

const DoubleToggleSlider = ({
  selectedValue,
  inputName,
  onToggle,
  toggleOptions,
  canToggle
}) => {
  
  instantiateLiveFormApply(selectedValue);

  const handleToggle = () => {
    if (canToggle) {
      onToggle(selectedValue === toggleOptions[0]['value'] ? toggleOptions[1]['value'] : toggleOptions[0]['value']);
    }
  };

  const getKnobState = () => {
    if (canToggle) {
      return selectedValue.toLowerCase();
    } else {
      return 'disabled'
    }
  }

  return (
    <div className={`double-toggle__container ${canToggle ? 'can-toggle' : ''}`}>
      <ToggleRadioInput 
       name={inputName}
       value={toggleOptions[0].value}
       checked={selectedValue === toggleOptions[0].value}
       label={toggleOptions[0].label}
       canToggle={canToggle}
       onChange={handleToggle}
      />
      <div className={`double-toggle-slider ${canToggle && 'active'}`} onClick={handleToggle}>
        <div className={`double-toggle-slider__knob ${getKnobState()}`}></div>
      </div>
      <ToggleRadioInput 
       name={inputName}
       value={toggleOptions[1].value}
       checked={selectedValue === toggleOptions[1].value}
       label={toggleOptions[1].label}
       canToggle={canToggle}
       onChange={handleToggle}
      />
    </div>
  );
};

export default DoubleToggleSlider;
