import React, { useState } from "react";
import { instantiateLiveFormApply } from "helpers/hotwire_helpers/LiveFormHelper";

const SimpleToggle = ({ toggled, paramName, canToggle }) => {
  const [isToggled, setIsToggled] = useState(toggled);

  instantiateLiveFormApply(isToggled);

  const handleToggle = (toggleAction) => {
    if (canToggle) {
      setIsToggled(toggleAction);
    }
  };

  return (
    <div className="toggle no-row-click">
      <input type="checkbox"
             name={paramName}
             checked={isToggled}
             value={isToggled} />

      <label checked={isToggled}>
        <div className={`toggle__slider ${!canToggle ? 'disabled' : ''}`} onClick={() => handleToggle(!isToggled)} />
      </label>
    </div>
  );
};

export default SimpleToggle;
