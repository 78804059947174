import React, { useEffect, useState } from "react";
import APIClient from "lib/APIClient";

const SaveToProject = ({ authToken, project, candidate, searchId }) => {
  const [saved, setSaved] = useState(candidate.saved);

  useEffect(() => {
    window.addEventListener("bulkSaved", (event) => {
      const candidateIds = event.detail;

      if (candidateIds.includes(String(candidate.id))) {
        setSaved(true);
      };
    });
  }, []);

  const handleSaveToProject = async () => {
    await APIClient.saveToProject(authToken, candidate.id, project.id, searchId);

    setSaved(true);
  };

  return (
    <div className="dropdown">
      <div className="dropdown__anchor">
        <a onClick={() => handleSaveToProject()}
            className={["button", "button--small", saved ? "button--disabled" : "" ].join(" ")}>{saved ? "Saved" : "Save"}
        </a>
      </div>
    </div>
  );
};

export default SaveToProject;
