
import React, { useState, useEffect, useRef } from 'react';
import downArrowIcon from '../../../assets/images/downArrowIcon.svg';

const truncateText = (text, maxLength) => {
  if (!text) return;
  
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

const StepPreview = ({ step, onDeleteStep }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const hasSent = step.sentCount > 0;
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="step-preview">
      <div>
        <div className="step-preview__header">
          <div className="step-preview__header__title">{ step.subject }</div>
        </div>
        <div className="step-preview__content__container">
          <div className='step-preview__content'>
            <div className='step-preview__content__body'>
              {truncateText(step.plainTextBody, 40)}
            </div>
          </div>
        </div>
      </div>
      <div className="step-preview__stats">
        <div className="step-preview__stats__stat">
          <div className="step-preview__stat__title">
            SENT
          </div> 
          <div className='step-preview__stat__value'>
            {hasSent ? step.sentCount : '-'}
          </div>
          </div>
        <div className="step-preview__stats__stat">
          <div className="step-preview__stat__title">
            OPENED
          </div>
          <div className='step-preview__stat__value'>
            {hasSent ? `${step.openRate}%` : '-'}
          </div>
        </div>
        <div className="step-preview__stats__stat">
          <div className="step-preview__stat__title">
            CLICKED
          </div>
          <div className='step-preview__stat__value'>
            {hasSent ? `${step.clickRate}%` : '-'}
          </div>
        </div>
        <div className="step-preview__stats__stat">
          <div className="step-preview__stat__title">
            REPLIED
          </div>
          <div className='step-preview__stat__value'>
            {hasSent ? `${step.responseRate}%` : '-'}
          </div>
        </div>
        {
          step.id && (
            <div className="down-arrow-icon-container" ref={dropdownRef}>
              <img src={downArrowIcon} onClick={toggleDropdown} />          
              {dropdownVisible && (
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={onDeleteStep}>
                    Remove
                  </div>
                </div>
              )}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default StepPreview;
