import React from 'react';

const StyleButton = ({ style, toggleStyle, isActive }) => (
  <button
    onMouseDown={event => event.preventDefault()}
    className={`style-button ${style.type.toLowerCase()} ${isActive ? 'active' : ''}`}
    onClick={() => toggleStyle(style.type)}
  >
    {style.label}
  </button>
);

export default StyleButton;

