import React from 'react';
import CustomEditor from '../CustomEditor';

const BodyEditor = ({ editorRef, editorState, onChange, handleKeyCommand }) => {
  return (
    <div className='text-editor__body-editor'>
      <CustomEditor
        editorRef={editorRef}
        editorState={editorState}
        onChange={onChange}
        handleKeyCommand={handleKeyCommand}
        placeholder="Compose a personalized message to your contacts..."
      />
    </div>
  );
};

export default BodyEditor;
