import React, { useState } from 'react';
import LocationOptions from './LocationOptions';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';

const LocationFilterDrawer = ({ paramName, existingSelectedOptions, usOnlyOption }) => {  
  const [usOnly, toggleUsOnly] = useState(usOnlyOption);
  
  instantiateLiveFormApply(usOnly);
  
  return (
    <>
      <div className="toggle">
        <input type="checkbox"
               readOnly
               name={'us_only'}
               value={usOnly}
               checked={usOnly}
               autoComplete="off" />

        <label checked="checked">
          <div className="toggle__slider" onClick={() => toggleUsOnly(!usOnly)} />

          US-only
        </label>
      </div>
      
      <LocationOptions name={paramName} existingSelectedLocations={existingSelectedOptions} />
    </>
  )
}

export default LocationFilterDrawer;
