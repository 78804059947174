import React, { useState } from 'react';
import APIClient from 'lib/APIClient';
import linkedInIcon from '../../assets/images/linkedin.svg';

const LinkedInUrl = ({ authToken, candidate, redirectOnClick }) => {
  const linkedInUrlPattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.*$/;
  const linkedInUrl = candidate.linkedInUrl;
  const hasValidLinkedInUrl = linkedInUrl && linkedInUrlPattern.test(linkedInUrl);

  const handleClick = (event) => {
    event.stopPropagation();
    const projectId = getProjectId();
    APIClient.trackLinkedInUrlClick(authToken, candidate.id, projectId);
  }

  const getProjectId = () => {
    const pathArray = window.location.pathname.split('/');
    const projectIndex = pathArray.indexOf('projects');
    let projectId = null;
    if (projectIndex !== -1 && pathArray.length > projectIndex + 1) {
        projectId = pathArray[projectIndex + 1];
    }

    return projectId;
  }

  const renderLinkedInIcon = () => {
    return <img src={linkedInIcon} alt="LinkedInIcon" className="contact__svg linkedin__icon" />;
  };

  return (
    redirectOnClick ? (
      <a href={linkedInUrl} onClick={handleClick} target="_blank">
        { renderLinkedInIcon() }
      </a>
    ) : (
      <div className="attribute search">
        { renderLinkedInIcon() }
        {hasValidLinkedInUrl ? (
          <a onClick={handleClick} href={linkedInUrl} target="_blank" rel="noopener">
            {linkedInUrl}
          </a>
        ) : (
          <span>N/A</span>
        )}
      </div>
    )
  );
}

export default LinkedInUrl;
