import React from 'react';

const SubjectEditor = ({ subject, handleSubjectChange }) => {
  return (
    <label>
      <textarea 
        value={subject} 
        onChange={handleSubjectChange} 
        rows="1" 
        cols="50"
        className='text-editor__subject-editor' />
    </label>
  )
};

export default SubjectEditor;
