import React, { useState, useEffect, useRef } from 'react';
import Contact from './Contact';
import LinkedInUrl from './LinkedInUrl';
import closeIcon from '../../assets/images/X.svg';
import loadingIcon from '../../assets/images/loading.gif';
import APIClient from 'lib/APIClient';

const EmailPromptModal = ({ authToken }) => {
 const [modalOpen, setModalOpen] = useState(false);
 const [candidateData, setCandidateData] = useState({});
 const [analysisData, setAnalysisData] = useState({});
 const [showCopied, setShowCopied] = useState(false);
 const [error, setError] = useState(null);
 const [loading, setLoading] = useState(false);

 const emailContentRef = useRef(null);

 const pollForEmailPitch = async (projectId, candidateId) => {
  let attempts = 0;
  const maxAttempts = 40;
  let pollInterval = 3000;

  const poll = async () => {
    if (attempts < maxAttempts) {
      if (attempts > 20) pollInterval = 5000;
      if (attempts > 30) pollInterval = 7000;
      attempts += 1;
      try {
        const response = await APIClient.checkCandidateEmailPitch(authToken, projectId, candidateId);
        if (response.data && response.data.email_pitch) {
          setAnalysisData(response.data);
          setLoading(false);
        } else {
          setTimeout(poll, pollInterval);
        }
      } catch (error) {
        setError('An error occurred while updating the data.');
        setLoading(false);
      }
    } else {
      setError('Timeout reached while waiting for the email pitch.');
      setLoading(false);
    }
  };

  poll();
};

  useEffect(() => {
    const handleOpenModal = async (event) => {
      const { candidate, projectId } = event.detail;

      setLoading(true);

      setCandidateData(event.detail.candidate);

      setModalOpen(true);
      
      const response = await APIClient.ensureProjectAndCandidateAnalysis(authToken, projectId, candidate.id);

      if (response.data && response.data.email_pitch) {
        setAnalysisData(response.data);
        setLoading(false);
      } else if (response.error) {
        setError(response.error);
        setLoading(false)      ;
      } else {
        pollForEmailPitch(projectId, candidate.id);   
      }
    };

    window.addEventListener('openModalWithCandidateData', handleOpenModal);

    return () => {
      window.removeEventListener('openModalWithCandidateData', handleOpenModal);
    };
  }, []);


  const renderEmailText = () => {
    return (
      <div ref={emailContentRef}>
        <div>Hi {candidateData.first_name},</div>
        <br/>
        <div dangerouslySetInnerHTML={{ __html: analysisData.email_pitch }} />
      </div>
    );
  };

  const renderBody = () => {
    if (error) {
      return error;
    }

    return (loading) ? (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={loadingIcon} style={{height: '100px', width: '100px'}}/>
      </div>
    ) : (
      <div>
        <div style={{padding: '20px', border: '1px solid #D6D6D6', height: '360px', maxHeight: '360px', overflow: 'scroll'}}>
          { renderEmailText() }
        </div>
        { analysisData.email_pitch && (
            <button 
              style={{float: 'right', width: '145px', height: '35px', borderRadius: '3px', border: '1px solid #5B4EFF', background: '#5B4EFF', cursor: 'pointer', color: 'white', marginTop: '10px'}}
              onClick={copyToClipboard}
            >
            { showCopied ? "Copied!" : "Copy email letter" }
          </button> 
        )}
      </div>
    )
  }

  const copyToClipboard = () => {
    if (emailContentRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(emailContentRef.current);
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand('copy');
      selection.removeAllRanges();

      setShowCopied(true); 
      setTimeout(() => setShowCopied(false), 800);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  }

  if (!modalOpen) return;

  return (
    <div 
      id="sideBarModal" 
      className="modal" 
      style={{ 
        width: '400px', 
        height: '100vh', 
        backgroundColor: 'white', 
        position: 'fixed', 
        top: 0, 
        right: 0,
        zIndex: '99999'
      }}
    >
      <div className='email-prompt-modal__candidate-info' style={{textAlign: 'center', padding: '20px 0px 30px 0px', borderBottom: '1px solid #F2F2F2'}}>
        <div style={{display: 'flex', justifyContent: 'flex-end', margin: '0px 20px', cursor: 'pointer'}}><img src={closeIcon} onClick={handleClose}/></div>
        <div className="candidate__summary__name" style={{fontSize: '20px', fontStyle: 'normal', fontWeight: '700' }}>
          {candidateData.full_name}
        </div>
        {candidateData.current_title && (
          <div className="candidate__summary__title">
              {`${candidateData.current_title} at `}<span style={{color: '#5B4EFF', textDecorationLine: 'underline'}}>{candidateData.current_company_name}</span>
          </div>
        )}
        <div className="candidate__summary__location" style={{color: '#828282', fontSize: '16px', fontStyle: 'normal', fontWeight: '400'}}>
          { candidateData.location }
        </div>
      </div>
      <div style={{margin: '20px', paddingBottom: '10px', borderBottom: '1px solid #F2F2F2'}}>
        <Contact authToken={authToken} candidateId={candidateData.id} hasValidMeail={candidateData['has_valid_email?'] } key={candidateData.id}/>
        <LinkedInUrl authToken={authToken} candidate={candidateData} />
      </div>
      <div style={{margin: '20px 20px 10px 20px'}}>
        { renderBody() }
      </div>
    </div>
  );
};

export default EmailPromptModal;
