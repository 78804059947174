import React from "react";

const UploadJobDescriptionLink = ({ projectId }) => {
  const handleUploadJobDescription = async () => {

  };

  return (
    <a onClick={() => handleUploadJobDescription()}>Upload job description</a>
  );
};

export default UploadJobDescriptionLink;
