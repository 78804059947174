import React, { useRef } from 'react';

const InjectionButton = ({ label, value, injectPlaceholder }) => {
  const buttonRef = useRef(null);

  const handleClick = () => {
    injectPlaceholder(value);
    buttonRef.current && buttonRef.current.blur();
    window.removeEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
    }
  };

  const handleFocus = () => {
    window.addEventListener('keydown', handleKeyDown);
  };

  return (
    <button
      className='injection-button'
      ref={buttonRef}
      onClick={handleClick}
      onFocus={handleFocus}
    >
      {`Insert ${label}`}
    </button>
  );
};

export default InjectionButton;
