export const extractPlainText = (contentJSON) => {
  try {
    const content = JSON.parse(contentJSON);
    return content.blocks.map(block => block.text).join('\n');
  } catch (error) {
    console.error('Failed to parse content JSON:', error);
    return '';
  }
};

export const normalizeWhiteSpace = (html) => {
  return html.replace(/<p>/g, '<p style="margin: 0;">');
};
