import React from 'react';

const AddLinkButton = ({ addLink }) => {
  const handleAddLinkClick = () => {
    const url = prompt("Please enter the URL:", "https://");
    if (url) {
      addLink(url, '');
    }
  };

  return (
    <button onClick={handleAddLinkClick} className='injection-button'>
        Insert Link
    </button>
  );
};

export default AddLinkButton;
