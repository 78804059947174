import React, { useState, useEffect } from "react";

const ProjectCompletionForm = ({ hideModal, updateProject }) => {
  const [notes, setNotes] = useState('');
  const [acquisitionType, setAcquisitionType] = useState(null);

  useEffect(() => {
   if (acquisitionType != "other") {
    setNotes("");
   }
  }, [acquisitionType]);

  const handleNotesChange = (event) => {
    event.preventDefault();
    setAcquisitionType("other");
    setNotes(event.target.value);
  };

  const handleSubmit = async (projectStatus) => {
    updateProject(projectStatus, notes, acquisitionType);
    cleanStates();
    hideModal();
  };

  const cleanStates = () => {
    setNotes('');
    setAcquisitionType(null);
  }

  return (
    <div className="complete-modal">
    <div className="complete-modal__overlay"
         onClick={hideModal}>
    </div>
      <div className="complete-modal__form">
        <div className="complete-modal__form-header__title">
          Why are you marking this project as complete?
        </div>
          <div className="complete-modal__form-body__content">
            <div className="modal-field">
              <input type="radio"
                id="internal_acquisition"
                checked={acquisitionType === "internal_acquisition"}
                onChange={() => setAcquisitionType("internal_acquisition")}
                autoComplete="off" />
              <label checked="checked" htmlFor="internal_acquisition">
                Found candidate through Mogul
              </label>
            </div>   
            <div className="modal-field">
              <input type="radio"
                id="external_acquisition"
                checked={acquisitionType === "external_acquisition"}
                onChange={() => setAcquisitionType("external_acquisition")}
                autoComplete="off" />
              <label checked="checked" htmlFor="external_acquisition">
                Found candidate outside of Mogul
              </label>
            </div>
            <hr></hr>
            <div className="modal-field">
              <input type="radio"
                id="other"
                checked={acquisitionType === "other"}
                onChange={() => setAcquisitionType("other")}
                autoComplete="off" />
            <label checked="checked" htmlFor="other">
              Other
            </label>
            </div>
            <div className="notes">
              <textarea
                value={notes}
                placeholder="Provide an explanation"
                onChange={handleNotesChange} />
            </div>
            <div className="modal-submit">
              <input type="submit"
                value="Submit"
                className="button" 
                disabled={!acquisitionType}
                onClick={() => handleSubmit('completed')} />
            </div>
          </div>
      </div>
    </div>
  );
};

export default ProjectCompletionForm;
