import React, {
  useEffect,
  useState
} from "react";
import greyEmailIcon from '../../assets/images/email_grey.svg';
import yellowEmailIcon from "../../assets/images/email_yellow.svg";

import APIClient from "lib/APIClient";
import CopyToClipboard from "components/CopyToClipboard";


export default function({ authToken, candidateId, searchId, hasValidEmail, projectId }) {
  const [requested, setRequested] = useState(false);
  const [contactInformation, setContactInformation] = useState({ email: null });
  const [validEmailFound, setValidEmailFound] = useState(hasValidEmail);

  async function handleContactClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const { email } = await APIClient.getCandidateContactInformation(authToken, candidateId, searchId, projectId);

    setContactInformation({
      email: email
    });

    setRequested(true);

    if (email !== "") {
      const event = new CustomEvent("validEmailFound", { detail: { candidateId: candidateId } });

      window.dispatchEvent(event);
    };
  };

  useEffect(() => {
    window.addEventListener("validEmailFound", (event) => {
      if (event.detail.candidateId == candidateId) {
        setValidEmailFound(true);
      };
    });
  }, []);
  
  return requested && (contactInformation.email || contactInformation.email == '') ? (
    <div className="attribute search" style={{paddingRight: '10px'}}>
      <img src={contactInformation.email !== "" ? yellowEmailIcon : greyEmailIcon} className="contact__svg" />

      {contactInformation.email !== '' ? (
        <CopyToClipboard body={contactInformation.email} />
      ) : (
        "N/A"
      )}
    </div>
  ) : (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div className="attribute search">
        <img src={validEmailFound ? yellowEmailIcon : greyEmailIcon} className="contact__svg" onClick={handleContactClick} />
      </div>
    </div>
  );
};
