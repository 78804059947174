import React, { useState } from "react";
import AddSequencePopup from "./AddSequencePopup";

const AddSequenceButton = ({ projects, authToken }) => {
  const [popupVisible, setPopupVisible] = useState(false);

  function handleClick(event) {
    event.preventDefault();

    setPopupVisible(!popupVisible);
  };

  function closePopup() {
    setPopupVisible(false);
  };

  return (
    <>
      <a onClick={handleClick} className="button-v2">
        Add sequence
      </a>
      
      <AddSequencePopup 
        projects={projects}
        visible={popupVisible}
        authToken={authToken}
        onExit={closePopup} 
      />
    </>
  );
};

export default AddSequenceButton;
