import React, {
  useEffect,
  useState,
} from "react";

const AiSearchContainer = () => {
  const authToken = '123'

  const [page, setPage] = useState(null);
  const [query, setQuery] = useState("");
  const [freeFormQuery, setFreeFormQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [refinements, setRefinements] = useState([]);
  const [highlight, setHighlight] = useState(null);
  const [reason, setReason] = useState("");

  function handlePreviousClick(event) {
    event.preventDefault();
    setPage(page - 1);
  };

  function handleNextClick(event) {
    event.preventDefault();
    setPage(page + 1);
  };

  function handleQueryChange(event) {
    setQuery(event.target.value);
  };

  function handleFreeFormQueryChange(event) {
    setFreeFormQuery(event.target.value);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await fetch('http://localhost:5006', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth0-User-ID": authToken
      },
      body: JSON.stringify({
        search_id: 150,
        query: query,
        free_form_query: freeFormQuery
      })
    });

    const responseJSON = await response.json();

    setSearchResults(responseJSON);
    setPage(1);
  };

  function handleMouseUp(event) {
    setHighlight({
      candidateId: event.currentTarget.dataset.candidateId,
      text: window.getSelection()?.toString() || ""
    });
  };

  async function handleReasonSubmit(event) {
    event.preventDefault();

    const response = await fetch(process.env.NEXT_PUBLIC_API_BASE_URL ?? "PLACEHOLDER", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth0-User-ID": authToken
      },
      body: JSON.stringify({
        query: query,
        free_form_query: freeFormQuery,
        refinements: [...refinements, { candidate_id: highlight?.candidateId, highlight: highlight?.text, reason: reason }]
      })
    });

    const responseJSON = await response.json();
    setSearchResults(responseJSON);

    setRefinements([...refinements, { candidate_id: highlight?.candidateId, highlight: highlight?.text, reason: reason }]);
    setHighlight(null);
    setReason("");
    setPage(1);
  };

  function handleReasonChange(event) {
    setReason(event.target.value);
  };

  async function handleSaveClick(candidateId, event) {
    event.preventDefault();

    await fetch((process.env.NEXT_PUBLIC_API_BASE_URL ?? "PLACEHOLDER") + "/saved_candidates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth0-User-ID": authToken
      },
      body: JSON.stringify({
        candidate_id: candidateId,
        search_id: searchResults["search_id"]
      })
    });
  };

  async function paginate(page) {
    const response = await fetch(process.env.NEXT_PUBLIC_API_BASE_URL ?? "PLACEHOLDER", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth0-User-ID": authToken
      },
      body: JSON.stringify({
        query: query,
        free_form_query: freeFormQuery,
        refinements: refinements,
        page: page,
        search_id: searchResults["search_id"]
      })
    });

    const responseJSON = await response.json();
    setSearchResults(responseJSON);
  };

  useEffect(() => {
    if (page) {
      paginate(page);
    }
  }, [page]);

  return (
    <div className="layout-row">
      <div className="layout-column">
        <form onSubmit={handleSubmit}>
          <input type="text"
                 value={query}
                 onChange={handleQueryChange} />

          {searchResults && (
            <input type="text"
                   value={freeFormQuery}
                   onChange={handleFreeFormQueryChange} />
          )}

          <input type="submit"
                 value="Search" />
        </form>

        {searchResults && (
          <div>
            {searchResults["candidates"].map((candidate) => {
              return (
                <div onMouseUp={handleMouseUp}
                     data-candidate-id={candidate.id}
                     className="candidate"
                     key={candidate.id}>
                  <div>
                    {candidate.full_name} | {candidate.current_title} | {candidate.industry} | {candidate.location} | {candidate.id} |
                    <a href="javascript:void(0);"
                       onClick={handleSaveClick.bind(null, candidate.id)}>Save</a>
                  </div>

                  {candidate.selection_reason && (
                    <p>
                      {candidate.selection_reason}
                    </p>
                  )}

                  <p>
                    {candidate.bio}
                  </p>

                  {candidate.experiences.length > 0 && (
                    <div>
                      <div>Experience</div>

                      <ul>
                        {candidate.experiences.map((experience) => {
                          return (
                            <li key={experience.title + experience.company_name}>
                              <div>
                                <div>
                                  <strong>{experience.title} | {experience.company_name} | {experience.date}</strong>

                                  <p>
                                    {experience.description}
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {candidate.educations.length > 0 && (
                    <div>
                      <div>Education</div>

                      <ul>
                        {candidate.educations.map((education) => {
                          return (
                            <li key={education.school_name + education.degree}>
                              <strong>{education.school_name} | {education.degree} | {education.date}</strong>

                              <p>
                                {education.description}
                              </p>

                              <p>
                                {education.activities_and_societies}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {searchResults && (
          <div>
            {page > 1 && (
              <a href="javascript:void(0)"
                 onClick={handlePreviousClick}>Previous</a>
            )}
            <a href="javascript:void(0)"
               onClick={handleNextClick}>Next</a>
          </div>
        )}
      </div>

      {highlight && (
        <div className="layout-column layout-column--emphasized">
          <p>
            Candidate ID: {highlight.candidateId}
          </p>

          <p>
            Highlighted text: {highlight.text}
          </p>

          <form onSubmit={handleReasonSubmit}>
            <input type="text"
                   value={reason}
                   onChange={handleReasonChange} />

            <input type="submit" />
          </form>
        </div>
      )}
    </div>
  );
};

export default AiSearchContainer;
