import React, { useEffect, useState } from "react";

const StepSettingsPopup = ({ step, visible, onExit, handleSaveSettings }) => {
  const resolveMinWaitDays = (step.order === 0 ? 0 : 1);
  
  const [daysToWait, setDaysToWait] = useState(step.waitDays || resolveMinWaitDays);

  useEffect(() => {
    setDaysToWait(step.waitDays);
  }, [step]);

  const handleDaysToWaitChange = (e) => {
    setDaysToWait(e.target.value);
  };

  return visible ? (
    <div className="popup-v2">
      <div className="popup-v2__overlay"
           onClick={onExit}>
      </div>

      <div className="popup-v2__popup">
        <form>
          <div className="popup-v2__popup__header">
            <div className="popup-v2__popup__header__title">
              Step Settings
            </div>
          </div>

          <div className="popup-v2__popup__content">
            <div className="form-v2">
              <div className="form-v2__field">
                <div>
                  <label htmlFor="sequence-name" className='popup-v2__popup__content__title'>Days to Wait</label>
                  <div className='popup-v2__popup__content__description'>Choose how long to wait to send this message if there is no reply</div>
                </div>

                <input type="number"
                       value={daysToWait}
                       onChange={handleDaysToWaitChange}
                       id="days-to-wait"
                       className='number-input-v2'
                       min={resolveMinWaitDays} />
              </div>
            </div>
          </div>

          <div className="popup-v2__popup__footer">
            <a onClick={onExit}
               className="button-v2 button-v2--secondary">Cancel</a>

            <a className='button-v2' 
               onClick={() => handleSaveSettings(daysToWait)} >
              Save
            </a>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default StepSettingsPopup;
