import { useRef, useState, useEffect } from 'react';
import { EditorState, RichUtils, convertToRaw, convertFromRaw, ContentState, Modifier } from 'draft-js';
import { isJSON } from '../../helpers/base_helper';
import { stateToHTML } from 'draft-js-export-html';
import TextDecorators from './textDecorators';

const useEditor = (body, setBody) => {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty(TextDecorators()));
  const lastInitializedBody = useRef(null);

  useEffect(() => {
    function initializeEditorState(body) {
      let newState;
      if (body && isJSON(body)) {
        const contentFromJSON = convertFromRaw(JSON.parse(body));
        newState = EditorState.createWithContent(contentFromJSON, TextDecorators());
      } else if (body) {
        const contentFromText = ContentState.createFromText(body);
        newState = EditorState.createWithContent(contentFromText, TextDecorators());
      } else {
        newState = EditorState.createEmpty(TextDecorators());
      }
      return EditorState.moveFocusToEnd(newState);
    }

    if (body !== lastInitializedBody.current) {
      const newState = initializeEditorState(body);
      setEditorState(newState);
      lastInitializedBody.current = body;
    }
  }, [body]);

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newBody = JSON.stringify(rawContentState);
    const htmlBody = stateToHTML(contentState, {});

    if (newBody !== lastInitializedBody.current) {
      setBody({ htmlBody, body: newBody} );
      lastInitializedBody.current = newBody;
    }
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (styleType) => {
    const newState = RichUtils.toggleInlineStyle(editorState, styleType);
    if (newState) {
      onChange(newState);
      editorRef.current.focus();
    }
  };

  const injectPlaceholder = (placeholderText) => {
    const selectionState = editorState.getSelection();
  
    const newContentState = Modifier.insertText(
      editorState.getCurrentContent(),
      selectionState,
      placeholderText
    );
  
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    );
    
    onChange(newEditorState);
  }

  const addLink = (url, anchorText = '') => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    if (!anchorText) anchorText = url;
  
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newContentState;

    if (selectionState.isCollapsed()) {
      newContentState = Modifier.insertText(
        contentState,
        selectionState,
        anchorText,
        null,
        entityKey
      );
    } else {
      newContentState = Modifier.applyEntity(
        contentStateWithEntity,
        selectionState,
        entityKey
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'apply-entity'
    );

    const newSelectionState = newEditorState.getSelection().merge({
      anchorOffset: selectionState.getEndOffset(),
      focusOffset: selectionState.getEndOffset()
    });

    const finalEditorState = EditorState.forceSelection(newEditorState, newSelectionState);

    onChange(finalEditorState);
  };
  
  return { editorRef, 
           editorState, 
           onChange, 
           handleKeyCommand, 
           toggleInlineStyle, 
           injectPlaceholder,
           addLink 
         };
};

export default useEditor;
