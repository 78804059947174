import React, { useState } from 'react';
import crmSettingsIcon from "../../../assets/images/crm_settings.svg";
import APIClient from 'lib/APIClient';

const UserSettingsButton = ({ authToken }) => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleInitialDisconnectClick = () => {
    setSettingsVisible(false);
    setConfirmVisible(true);
  }

  const handleConfirmDisconnect = async () => {
    const response = await APIClient.disconnectEmail(authToken);

    if (response.status === 200) {
      window.location.reload();
    }
  }

  return (
    <>
      <img 
        src={crmSettingsIcon} 
        onClick={() => setSettingsVisible(!settingsVisible)}
        className='crm-settings-icon' />

        {settingsVisible && (
          <div className='popup-v2'>
            <div className="popup-v2__overlay"
                 onClick={() => setSettingsVisible(false)}>
            </div>
            <div className="popup-v2__popup">
              <div className='connect-oauth-popup__content'>
                <div className='connect-oauth-popup__content__header'>
                  Disconnect your email account
                </div>
                <div className='connect-oauth-popup__content__sub-header'>
                  Disconnecting your email account will remove our ability to send and sync emails with your inbox.
                </div>
                <div className='connect-oauth-popup__content__body'>
                  <div 
                    className='connect-oauth-popup__content__body__button disconnect-button'
                    onClick={() => handleInitialDisconnectClick()}
                  >
                    DISCONNECT EMAIL
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {confirmVisible && (
          <div className='popup-v2'>
            <div className="popup-v2__overlay"
                 onClick={() => setConfirmVisible(false)}>
            </div>
            <div className="confirm-popup__popup">
              <div className='connect-oauth-popup__content confirm-popup__content'>
                <div className='connect-oauth-popup__content__header confirm-popup__content__header'>
                  Are you sure you want to disconnect?
                </div>
                <div className='confirm-popup__content__body'>
                  <button 
                    className='button cancel-button'
                    onClick={() => setConfirmVisible(false)}
                  >
                    CANCEL
                  </button>
                  <button 
                    className='button disconnect-button'
                    onClick={handleConfirmDisconnect}
                  >
                    DISCONNECT EMAIL
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default UserSettingsButton;
