import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import ProjectStatus from 'components/ProjectStatus';
import APIClient from "lib/APIClient";
import ProjectCompletionForm from "components/ProjectCompletionForm";

const ProjectMenuDropdown = ({ authToken, project }) => {
  const projectId = project.id
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [status, setStatus] = useState(project.status);
  const [displayModal, setDisplayModal] = useState(false);
   
  const activeProject = status === "active";
  const archivedProject = status === "archived";
  const completedProject = status === "completed";
  
  const updateProject = async (projectStatus, notes='', acquisitionType=null) => {
    const response = await APIClient.updateProject(authToken, projectId, projectStatus, notes, acquisitionType);

    setStatus(response.status);
    setDisplayDropdown(false);
  }

  return (
    <div className="project-menu-container">
      <div className="project-menu-dropdown">
        <Dropdown
          onMouseLeave={() => setDisplayDropdown(false)}
          onClick={() => setDisplayDropdown(true)}
          align="end">
          
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            ...
          </Dropdown.Toggle>

          {displayDropdown && (
            <Dropdown.Menu show={displayDropdown} className="dropdown-menu">
              {activeProject && (
                <Dropdown.Item>
                  <div onClick={() => setDisplayModal(true)}>
                    Mark project as complete
                  </div>
                </Dropdown.Item>
              )}
              {completedProject && (
                <Dropdown.Item>
                  <div onClick={() => updateProject("active")}>
                    Mark project as active
                  </div>
                </Dropdown.Item>
              )}
              {activeProject && (
                <Dropdown.Item>
                  <div onClick={() => updateProject("archived")}>
                    Archive project
                  </div>
                </Dropdown.Item>
              )}
              {archivedProject && (
                <Dropdown.Item>
                  <div onClick={() => updateProject("active")}>
                    Unarchive project
                  </div>
                </Dropdown.Item>
              )} 
            </Dropdown.Menu>
            )}
        </Dropdown>
        {displayModal && (
          <ProjectCompletionForm 
            hideModal={()=>setDisplayModal(false)}
            updateProject={updateProject}/>
        )}
      </div>
      <ProjectStatus status={status} />
    </div>
  );
};

export default ProjectMenuDropdown;
