import Project from "models/Project";

class APIClient {
  static request = async ({ path, method, keepAlive, authToken, body }) => {
    const fetchArguments = {
      method: method,
      headers: {
        "Accept": "application/json",
        "X-Tool": "recruiter"
      }
    };

    if (authToken) {
      fetchArguments["headers"]["X-Auth-Token"] = authToken;
    };

    if (body) {
      fetchArguments["body"] = JSON.stringify(body);
    };

    if (keepAlive) {
      fetchArguments["headers"]["keepalive"] = keepAlive;
    };

    if (body && !(body instanceof FormData)) {
      fetchArguments["headers"]["Content-Type"] = "application/json";
      fetchArguments["body"] = JSON.stringify(body);
    } else {
      fetchArguments["body"] = body;
    }

    return fetch(`/api${path}`, fetchArguments);
  };

  static getProjects = async (authToken) => {
    let url = "/projects";

    const response = await APIClient.request({
      path: url,
      method: "GET",
      authToken
    });
    const responseJSON = await response.json();

    return responseJSON;
  };

  static createProject = async (authToken, name) => {
    let url = `/projects`;

    const response = await APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        project: {
          name: name
        }
      }
    });
    const responseJSON = await response.json();

    return JSONToProject(responseJSON);
  };

  static createProjectFromJob = async (authToken, jobId, filters) => {
    let url = `/projects/create_from_job/${jobId}`;

    const response = await APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        filters: filters
      }
    });
    
    const json = await response.json();
    return json.project;
  };

  static renameProject = async (authToken, projectId, name) => {
    let url = `/projects/${projectId}/update_name/`;
    await APIClient.request({
      path: url,
      method: "PATCH",
      authToken,
      body: {
        project: {
          id: projectId,
          name: name
        }
      }
    });
  }

  static saveToProject = (authToken, candidateId, projectId, searchId) => {
    let url = `/candidates/${candidateId}/save`;

    APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        project_id: projectId,
        search_id: searchId
      }
    });
  };

  static bulkSaveToProject = (authToken, candidateIds, projectId, searchId) => {
    APIClient.request({
      path: "/candidates/bulk_save",
      method: "POST",
      authToken,
      body: {
        project_id: projectId,
        candidate_ids: candidateIds,
        search_id: searchId
      }
    });
  };

  static updateSavedCandidate = async (authToken, savedCandidateId, { resume, assessment, status }) => {
    let url = `/saved_candidates/${savedCandidateId}`;

    await APIClient.request({
      path: url,
      method: "PATCH",
      authToken,
      body: {
        saved_candidate: {
          resume,
          assessment,
          status
        }
      }
    });
  };

  static getCandidateContactInformation = async (authToken, candidateId, searchId, projectId=null) => {
    let url = `/candidates/${candidateId}/contact`;

    const response = await APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        search_id: searchId,
        project_id: projectId
      }
    });


    const responseJSON = await response.json();

    return {
      email: responseJSON.email,
      linkedInURL: responseJSON.linked_in_url,
      canDisplayEmail: responseJSON.can_display_email
    };
  };

  static getCandidateProfileInformation = async (authToken, candidateId) => {
    const url = `/candidates/${candidateId}/get_profile_information`

    const response = await APIClient.request({
      path: url,
      method: "GET",
      authToken
    })

    const responseJSON = await response.json();

    return {
      candidate: responseJSON
    }
  }

  static getCandidateProjectMatchInformation = async (authToken, projectId, candidateId) => {
    const url = `/projects/${projectId}/candidate_match_info`

    return APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        candidate_id: candidateId
      }
    })
  }

  static getCandidateEmailDiscoverableInformation = async (authToken, candidateId) => {
    let url = `/candidates/${candidateId}/discoverable`;

    const response = await APIClient.request({
      path: url,
      method: "GET",
      authToken
    });

    const responseJSON = await response.json();

    return {
      discoverable: responseJSON.discoverable
    };
  }

  static fetchCompanyDataFromExperience = async(authToken, experienceId) => {
    let url = `/experiences/${experienceId}/fetch_company_data`;

    const response = await APIClient.request({
      path: url,
      method: "GET",
      authToken
    });

    const responseJSON = await response.json();

    return {
      company: responseJSON.data
    };
  }

  static trackAppSession = (authToken, duration, projectId=null) => {
    let url = `/app_sessions`;

    APIClient.request({
      path: url,
      method: "POST",
      keepAlive: true,
      authToken,
      body: {
        app_session: {
          duration: duration,
          session_type: 'recruiter',
          project_id: projectId
        }
      }
    });
  };

  static updateProject = async (authToken, projectId, projectStatus, notes, acquisitionType) => {
    let url = `/projects/${projectId}/update_status`;

    const response = await APIClient.request({
      path: url,
      method: "PATCH",
      authToken,
      body: {
        project: {
          status: projectStatus,
          notes: notes ? notes : '',
          acquisition_type: acquisitionType ? acquisitionType : null
        }
      }
    })

    const responseJSON = await response.json();

    return responseJSON.project;
  };

  static ensureProjectAndCandidateAnalysis = async (authToken, projectId, candidateId) => {
    const url = `/projects/${projectId}/ensure_project_and_candidate_analysis`

    const response = await APIClient.request({
      path: url,
      method: 'POST',
      authToken,
      body: {
        candidate_id: candidateId
      }
    })

    const responseJSON = await response.json();

    return responseJSON;
  }

  static checkCandidateEmailPitch = async (authToken, projectId, candidateId) => {
    const url = `/projects/${projectId}/candidate_email_pitch_info`;

    const response = await APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        candidate_id: candidateId
      }
    })

    const responseJSON = await response.json();

    return responseJSON;
  }

  static trackLinkedInUrlClick = (authToken, candidateId, projectId=null) => {
    const url = `/candidates/${candidateId}/track_linked_in_click`;

    APIClient.request({
      path: url,
      method: "POST",
      authToken,
      body: {
        project_id: projectId
      }
    })
  }

  static saveEmailTemplate = async (
    authToken, 
    emailTemplateId, 
    formattedBody, 
    htmlBody,
    plainTextBody, 
    subject) => {
    const response = await APIClient.request({
      path: "/email_templates/save",
      method: "POST",
      authToken,
      body: {
        email_template: {
          id: emailTemplateId,
          formatted_body: formattedBody,
          plain_text_body: plainTextBody,
          html_body: htmlBody,
          subject: subject
        }
      }
    });

    return response;
  };

  static saveCRMStep = async (
    authToken,
    stepId,
    emailTemplateId,
    sequenceId,
  ) => {
    const response = await APIClient.request({
      path: '/steps/save',
      method: "POST",
      authToken,
      body: {
        step: {
          id: stepId,
          email_template_id: emailTemplateId,
          sequence_id: sequenceId,
        }
      }
    })

    return response;
  }

  static requestSequenceSteps = async (
    authToken,
    sequenceId
  ) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/steps`,
      method: 'GET',
      authToken
    })

    const responseJSON = await response.json();
    return responseJSON;
  }

  static fetchSequences = async (
    authToken,
  ) => {
    const response = await APIClient.request({
      path: `/sequences`,
      method: 'GET',
      authToken
    })

    const responseJSON = await response.json();
    return responseJSON;
  }

  static addCandidateToSequence = async (authToken, sequenceId, candidateId) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/contacts`,
      method: 'POST',
      authToken,
      body: { candidate_id: candidateId },
    });

    const responseJSON = await response.json();
    
    return responseJSON;
  }

  static toggleSequenceActivation = async (
    authToken,
    sequenceId,
    active 
  ) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/toggle_activation`,
      method: 'PATCH',
      authToken,
      body: {
        sequence: {
          active: active
        }
      }
    })

    return response;
  }

  static updateStepSettings = async (
    authToken,
    stepId,
    sequenceId,
    waitDays
  ) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/steps/${stepId}/update_settings`,
      method: 'PATCH',
      authToken,
      body: {
        step: {
          wait_days: waitDays
        }
      }
    })

    return response;
  }

  static deleteStep = async (
    authToken,
    stepId,
    sequenceId
  ) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/steps/${stepId}`,
      method: 'DELETE',
      authToken
    })

    return response;
  }

  static deleteSequenceContact = async (
    authToken,
    sequenceId,
    contactId
  ) => {
    const response = await APIClient.request({
      path: `/sequences/${sequenceId}/contacts/${contactId}`,
      method: 'DELETE',
      authToken
    })

    return response;
  }

  static importProjectToSequence = async (
    authToken,
    projectId,
    sequenceTitle
  ) => {
    const response = await APIClient.request({
      path: `/sequences`,
      method: 'POST',
      authToken,
      body: {
        project_id: projectId,
        title: sequenceTitle
      }
    })

    return response;
  }

  static disconnectEmail = async (authToken) => {
    const response = await APIClient.request({
      path: '/oauth_credentials/disconnect_email',
      method: 'POST',
      authToken
    })

    return response;
  }

  static uploadCandidatesToJob = async (jobId, formData, authToken) => {
    const response = await APIClient.request({
      path: `/jobs/${jobId}/job_candidates`,
      method: 'POST',
      authToken,
      body: formData
    });

    return response;
  };

  static removeCandidateFromJob = async (jobId, jobCandidateId, authToken) => {
    const response = await APIClient.request({
      path: `/jobs/${jobId}/job_candidates/${jobCandidateId}`,
      method: 'DELETE',
      authToken
    });

    return response;
  }

  static getJobStatus = async (jobId, authToken) => {
    const response = await APIClient.request({
      path: `/jobs/${jobId}/status`,
      method: 'GET',
      authToken
    })

    return response;
  }
  
  static getJobCandidates = async (jobId, authToken) => {
    const response = await APIClient.request({
      path: `/jobs/${jobId}/candidates`,
      method: 'GET',
      authToken
    })

    return response;
  }

  static deleteJob = async (jobId, authToken) => {
    const response = await APIClient.request({
      path: `/jobs/${jobId}`,
      method: 'DELETE',
      authToken
    })
  }

  static getInclusivityTextSuggestions = async (authToken, text) => {
    const response = await APIClient.request({
      path: `/tools/inclusivity_tools/text_suggestions`,
      method: 'POST',
      authToken,
      body: {
        text: text
      }
    })

    return response;
  }

  static checkJobStatus = async (authToken, job_id) => {
    const response = await APIClient.request({
      path: `/tools/inclusivity_tools/job_status`,
      method: 'POST',
      authToken,
      body: {
        job_id: job_id
      }
    });
  
    return response;
  }
};

function JSONToProject(JSON) {
  return new Project(JSON.id, JSON.name, JSON.number_of_candidates, JSON.created_at_date);
};

export default APIClient;
