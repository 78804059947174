import React from "react";
import APIClient from "lib/APIClient";

const SequenceActivationButton = ({ sequence, authToken  }) => {
  const sequenceActive = sequence.active;

  const handleSequenceActivation = async () => {
    try {
      await APIClient.toggleSequenceActivation(authToken, sequence.id, !sequenceActive);
      window.location.reload();
    } catch (error) {
      console.error('Error updating step settings:', error);
    }
  };

  return (
    <button onClick={handleSequenceActivation} className={`sequence-activation-button ${sequenceActive ? 'active' : 'inactive'}`}>
      {sequenceActive ? "PAUSE" : "LAUNCH"}
    </button>
  );
}

export default SequenceActivationButton;
