import React, { useState } from 'react';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';

const OpenToOpportunitiesFilter = ({ existingSelectedOptions, options }) => {
  const [openToOpportunities, setOpenToOpportunities] = useState(existingSelectedOptions);
  
  instantiateLiveFormApply(openToOpportunities);

  const matchCount = options.find((option) => option['name'] === true)?.['matchCount'] || 0;

  return (
    <>
      <div className="toggle">
        <input type="checkbox"
               readOnly
               name='open_to_opportunities'
               value={openToOpportunities}
               checked={openToOpportunities}
               autoComplete="off" 
               />

        <label checked="checked">
          <div className="toggle__slider" onClick={() => setOpenToOpportunities(!openToOpportunities)}></div>

          Likely open to opportunities ({matchCount})
        </label>
      </div>
    </>
  )
}

export default OpenToOpportunitiesFilter;
