export const handleToggleDrawer = (boxIsOpen, toggleDrawer) => {
  boxIsOpen ? toggleDrawer('false') : toggleDrawer('true');
}

export const handleClearFilter = (e) => {
  const $textInputs = e.currentTarget.parentElement.parentElement.parentElement.querySelectorAll("input[type=\"text\"]");
  const $checkboxes = e.currentTarget.parentElement.parentElement.parentElement.querySelectorAll("input[type=\"checkbox\"]");

  $checkboxes.forEach($checkbox => $checkbox.checked = false);
  $textInputs.forEach($textInput => $textInput.value = "");

  const event = new Event("liveFormController.apply");
  document.dispatchEvent(event);
};
