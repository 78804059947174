import React from 'react';

const CreateProjectButton = (props) => {
  const { 
    creatingProject, 
    handleSubmit
  } = props;

  return (
    <button 
      className='create-project-button' 
      onClick={handleSubmit}
      disabled={creatingProject}
    >
      {creatingProject ? 'Creating Project...' : 'Create a new project'}
    </button>
  )
}

export default CreateProjectButton;
