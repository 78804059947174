import React, { useState, useRef, useEffect } from 'react';
import APIClient from 'lib/APIClient';
import acceptChangesIcon from "../../../assets/images/accept_changes_icon.svg";
import revertChangesIcon from "../../../assets/images/revert_changes_icon.svg";
import informationIcon from "../../../assets/images/information_icon.svg";
import loadingGifIcon from "../../../assets/images/loading_gif_icon.gif";
import inputArrowIcon from "../../../assets/images/input_arrow.svg";
import revertArrowIcon from "../../../assets/images/revert_arrow.svg";

const JobDescriptionInclusivityTool = ({ authToken }) => {
  const [text, setText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [inclusivityScore, setInclusivityScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [highlightedText, setHighlightedText] = useState(null);
  const [clickedSuggestions, setClickedSuggestions] = useState(new Set());
  const contentBoxRef = useRef(null);
  const highlightedRef = useRef(null);

  const handleDivInputChange = (e) => {
    setText(e.currentTarget.textContent);
  };

  const handleClearText = () => {
    if (loading) return;

    setText('');
    setLoading(false);
    setSuggestions([]);
    setInclusivityScore(0);
    setError('');
    setClickedSuggestions(new Set());
  }

  const handleAnalyzeJobDescription = async () => {
    if (loading) return;

    setLoading(true);
    setError('');
    setSuggestions([]);
    setClickedSuggestions(new Set());
    setInclusivityScore(0);
  
    const startResponse = await APIClient.getInclusivityTextSuggestions(authToken, text);
  
    if (startResponse.ok) {
      const { job_id } = await startResponse.json();
      pollJobStatus(job_id);
    } else {
      setError('Failed to start analysis job');
      setLoading(false);
    }
  };

  const pollJobStatus = async (jobId) => {
    const intervalId = setInterval(async () => {
      const response = await APIClient.checkJobStatus(authToken, jobId);
  
      if (response.ok) {
        const data = await response.json();
  
        if (data.status === 'complete') {
          clearInterval(intervalId);
          setSuggestions(data.suggestions);
          setInclusivityScore(data.inclusivity_score);
          setLoading(false);
        } else if (data.status === 'failed') {
          clearInterval(intervalId);
          setError('The analysis failed. Please try again.');
          setLoading(false);
        }
      } else {
        clearInterval(intervalId);
        setError('Failed to check job status');
        setLoading(false);
      }
    }, 3000);
  };

  const handleSuggestionClick = (originalText, suggestion, delta) => {
    const updatedText = text.replace(originalText, suggestion);
    setText(updatedText);
    setClickedSuggestions(new Set(clickedSuggestions).add(originalText));
    
    adjustInclusivityScore(delta);
  };

  const handleUndoClick = (event, originalText, suggestion, delta) => {
    event.stopPropagation(); 

    const updatedText = text.replace(suggestion, originalText);
    setText(updatedText);
  
    const updatedClickedSuggestions = new Set(clickedSuggestions);
    updatedClickedSuggestions.delete(originalText);

    setClickedSuggestions(updatedClickedSuggestions);

    adjustInclusivityScore(-delta);
  };
  
  const handleSuggestionHover = (originalText) => {
    setHighlightedText(originalText);
  };

  const handleTextBoxClick = () => {
    if (loading) return;

    if (contentBoxRef.current) {
      document.execCommand('selectAll', false, null);
    }
  };

  const handleSuggestionMouseLeave = () => {
    setHighlightedText(null);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    
    if (loading) return;
    
    handleClearText();

    const pastedText = e.clipboardData.getData('text');
    setText(pastedText);
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Escape',
    ];
    const nonCharacterKeys = ['Shift', 'Control', 'Alt', 'Meta'];
  
    if (
      allowedKeys.includes(e.key) ||
      nonCharacterKeys.includes(e.key) ||
      ((e.ctrlKey || e.metaKey) && ['c', 'v', 'a', 'r'].includes(e.key.toLowerCase()))
    ) {
      return;
    }
  
    e.preventDefault();
  };

  useEffect(() => {
    if (highlightedRef.current && contentBoxRef.current) {
      const contentBox = contentBoxRef.current;
      const highlighted = highlightedRef.current;
      const highlightedPosition = highlighted.offsetTop - contentBox.offsetTop;
      contentBox.scrollTo({
        top: highlightedPosition - contentBox.clientHeight / 2,
        behavior: 'smooth',
      });
    }
  }, [highlightedText]);

  const getHighlightedText = () => {
    const normalizedHighlightedText = highlightedText
      ? highlightedText.replace(/\s+/g, ' ').trim()
      : '';
  
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };
  
    const highlightPatterns = [...clickedSuggestions]
      .map((originalText) => {
        const suggestion = suggestions.find(
          (s) => s.original_text === originalText
        );
        return suggestion?.suggestion;
      })
      .concat(normalizedHighlightedText)
      .filter(Boolean)
      .map(escapeRegExp);
  
    if (highlightPatterns.length === 0) {
      return text;
    }
  
    let foundHighlight = false;
  
    const parts = text.split(
      new RegExp(`(${highlightPatterns.join('|')})`, 'gi')
    );  
  
    return parts.map((part, index) => {
      if (!foundHighlight && part?.replace(/\s+/g, ' ')?.trim()?.toLowerCase() === normalizedHighlightedText.toLowerCase()) {
        foundHighlight = true;
        const suggestion = suggestions.find(
          (s) => s.original_text === highlightedText
        );
  
        return (
          <span key={index} ref={highlightedRef}>
            {part ? (
              <>
                <span style={{ textDecoration: 'line-through', backgroundColor: '#FFF3FF', padding: '1px' }}>
                  {part}
                </span>
                <br />
              </>
            ) : null}
            <span style={{ backgroundColor: '#FFF3FF', display: part ? 'block' : 'inline' }}>
              {suggestion?.suggestion}
            </span>
          </span>
        );
      }
  
      const isClickedSuggestion = [...clickedSuggestions].some(
        (originalText) => {
          const suggestion = suggestions.find(
            (s) => s.original_text === originalText
          );
          return suggestion?.suggestion === part;
        }
      );
  
      if (isClickedSuggestion) {
        foundHighlight = true;
        return (
          <span key={index} style={{ backgroundColor: '#FFF3FF', padding: '2px' }}>
            {part}
          </span>
        );
      }
  
      return part;
    });
  };
  
  const scoreClass = (score) => {
    if (score <= 3.3) {
      return 'low';
    } else if (score <= 6.6) {
      return 'medium';
    } else {
      return 'high';
    } 
  }

  const adjustInclusivityScore = (delta) => {
    setInclusivityScore(prevScore => {
        let newScore = prevScore + delta;
        return newScore < 0 ? 0 : newScore;
    });
  };

  return (
    <div className="job-description__inclusivity-tool">
      <div className="job-description__inclusivity-tool__sidebar">
        {loading ? (
          <div className="job-description__inclusivity-tool__sidbar__score-box loading">
            <div>
              <p>Inclusion score</p>
              <p className="job-description__inclusivity-tool__sidbar__score-box__score">
                <img style={{width: "49px", height: "49px"}} src={loadingGifIcon} />
              </p>
            </div>
          </div>
        ) : (
          (inclusivityScore > 0 || suggestions.length > 0) && (
            <div className={`job-description__inclusivity-tool__sidbar__score-box ${scoreClass(inclusivityScore.toFixed(1))}`}>
              <div>
                <p>Inclusion score</p>
                <p className="job-description__inclusivity-tool__sidbar__score-box__score">
                  {inclusivityScore.toFixed(1)} / 10
                </p>
              </div>
            </div>
          )
        )}
      </div>
      <div className={`job-description__inclusivity-tool__content-box-main-container ${text.length > 0 ? "" : "empty"}`}>
        <div className="job-description__inclusivity-tool__content-box-main-container__title">Job Description Optimization</div>
        { text.length == 0 && <div style={{marginBottom: '20px'}}>Copy and paste your job description below and Mogul AI will give you suggestions to make your job description more DEI friendly</div>}
        <div className={`job-description__inclusivity-tool__content-box-inside-container ${loading ? 'is-loading' : ''}`}>
          <div
            className="job-description__inclusivity-tool__content-box"
            contentEditable={true}
            suppressContentEditableWarning={true}
            ref={contentBoxRef}
            onInput={handleDivInputChange}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            style={{ minHeight: '48px', color: text ? 'initial' : '#C8C8C8' }}
            onClick={handleTextBoxClick} 
          >
            {text ? (
              getHighlightedText()
            ) : (
              <span style={{ pointerEvents: 'none' }}>Copy and paste here</span>
            )}
          </div>
          <div className={`job-description__inclusivity-tool__content-box-inside-container__actions ${loading ? 'is-loading' : ''}`}>
            { text.length > 0 && <img src={revertArrowIcon} onClick={handleClearText} style={{marginRight: '8px'}} /> }
            <img src={inputArrowIcon} onClick={handleAnalyzeJobDescription} />
          </div>
        </div>
      </div>
      <div
        className="job-description__inclusivity-tool__suggestions-container"
        style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}
      >
        {loading ? (
          <div className="job-description__inclusivity-tool__suggestions loading">
            <div>Loading Suggestions</div>
            <img src={loadingGifIcon } style={{width: "49px", height: "49px"}}/>
          </div>
        ) : (
          <div className="job-description__inclusivity-tool__suggestions">
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {suggestions.map((suggestion, index) => {
              const acceptedSuggestion = clickedSuggestions.has(suggestion.original_text);

              return (
                <div
                  key={index}
                  className={`job-description__inclusivity-tool__suggestions__item ${acceptedSuggestion ? 'accepted' : ''}`}
                  onMouseEnter={() => handleSuggestionHover(suggestion.original_text)}
                  onMouseLeave={handleSuggestionMouseLeave}
                >
                  <div className='job-description__inclusivity-tool__suggestions__item__suggestion-prompt-container'>
                    <p style={{paddingBottom: '10px', margin: '0'}}>{suggestion.suggestion}</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      {acceptedSuggestion ? (
                        <img 
                          src={revertChangesIcon} 
                          onClick={(event) => handleUndoClick(event, suggestion.original_text, suggestion.suggestion, suggestion.delta)} 
                          className='action-icon'
                        />
                      ) : (
                        <img 
                          src={acceptChangesIcon} 
                          onClick={() => handleSuggestionClick(suggestion.original_text, suggestion.suggestion, suggestion.delta)} 
                          className='action-icon'
                        />
                      )}
                    </div>
                  </div>
                  <div className='job-description__inclusivity-tool__suggestions__item__explanation'>
                    <div className='explanation-header'>
                      <img src={informationIcon} alt="info icon"/>Why we made this suggestion
                    </div>
                    {suggestion.explanation}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>      
    </div>
  );
};

export default JobDescriptionInclusivityTool;
