import React, { useState, useEffect } from 'react';
import loadingIcon from '../../assets/images/loading.gif';
import locationPin from '../../assets/images/location_pin.svg';
import calendar from '../../assets/images/calendar.svg';
import industryFlag from '../../assets/images/industry_flag.svg';
import employees from '../../assets/images/employees_icon.svg';
import linkIcon from '../../assets/images/link_icon.svg';
import closeIcon from '../../assets/images/X.svg';
import APIClient from 'lib/APIClient';

const subHeaderStyle = {
  color: '#767676',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  marginRight: '5px'
}

const CompanyProfileSidebar = ( { authToken }) => {
 const [companyData, setCompanyData] = useState({});
 const [loading, setLoading] = useState(false);
 const [visibleCandidateProfileContainer, setVisibleCandidateProfileContainer] = useState(null);
 const [companyProfileSideBarContainer, setCompanyProfileSideBarContainer] = useState(null);

 const candidateDataRow = document.getElementById('candidateResultsContainer');

 function findVisibleCandidateProfile() {
  const candidateProfiles = document.querySelectorAll('.candidate-profile-container');
  return Array.from(candidateProfiles).find(profile => profile.style.display === 'block')?.parentElement;
}

  useEffect(() => {
    const handleOpenModal = async (event) => {
      const { 
        experienceId, 
        visibleCandidateProfileContainer, 
        companyProfileSideBarContainer,
      } = event.detail;    

      setLoading(true);

      setCompanyProfileSideBarContainer(companyProfileSideBarContainer);

      if (visibleCandidateProfileContainer) {
        setVisibleCandidateProfileContainer(visibleCandidateProfileContainer);
        visibleCandidateProfileContainer.style.display = 'none';
      }

      document.getElementById('companyProfileSideBar').style.display = 'block'
      
      const response = await APIClient.fetchCompanyDataFromExperience(authToken, experienceId);
      
      if (response.company) {
        setCompanyData(response.company);
      } 

      setLoading(false);
    };

    window.addEventListener('openCompanyProfileSidebar', handleOpenModal);

    return () => {
      window.removeEventListener('openCompanyProfileSidebar', handleOpenModal);
    };
  }, []);

  const handleClose = () => {
    if (companyProfileSideBarContainer) {
      companyProfileSideBarContainer.style.display = 'none';
    }

    document.getElementById('companyProfileSideBar').style.display = 'none';

    candidateResultsContainer.className = 'layout-column scrollable';
    
    const maxFilter = document.getElementById('maxFilter')
    if (maxFilter) {
      maxFilter.style.display = 'block';
    }
    
    const minFilter = document.getElementById('minFilter')
    if (minFilter) {
      minFilter.style.display = 'none';
    }
  }

  const handleBack = () => {
    const visibleCandidateProfileContainer = findVisibleCandidateProfile()
    if (companyProfileSideBarContainer && visibleCandidateProfileContainer) {
      companyProfileSideBarContainer.style.display = 'none';
      visibleCandidateProfileContainer.style.display = 'block';
    } else {
      handleClose();
    }
  }

  const renderBody = () => {
    return (loading) ? (
      <div className="loading-container">
        <img src={loadingIcon} className="loading-icon"/>
      </div>
    ) : (
      <div className='company-profile-container'>
        <div className='company-profile-header'>
          <div className='company-profile-header__actions'>
            <div className='back-button' onClick={handleBack}>← Go back</div>
            <img src={closeIcon} className='close-icon' onClick={handleClose}/>
          </div>
          <div className='company-profile-header__name hello'>
            {companyData.name}
          </div>
        </div>
        <div className='company-profile__data'>
          <div className='data-grid'>
            <div className='data-column'>
              <div className='location-column'>
                <img src={locationPin} className='icon-small'/>
                <span className='sub-header'>Location:</span>
                <span className='data-text'>{companyData.location || 'N/A'}</span>
              </div>
              <div className='year-founded-column'>
                <img src={calendar} className='icon-small'/>
                <span className='sub-header'>Year Founded:</span>
                <span className='data-text'>{companyData.founded || 'N/A'}</span>
              </div>
            </div>
            <div className='data-column padding-left'>
              <div className='industry-column'>
                <img src={industryFlag} className='icon-small'/>
                <span className='sub-header'>Industry:</span>
                <span className='data-text'>{companyData.industry || 'N/A'}</span>
              </div>
              <div className='employees-column'>
                <img src={employees} className='icon-small'/>
                <span className='sub-header'>Employees:</span>
                <span className='data-text'>{companyData.employees_count || 'N/A'}</span>
              </div>
            </div>
            <div className='data-column padding-left'>
              { companyData.website && (
                <div>
                  <img src={linkIcon} className='icon-small' />
                  <a href={companyData.website} target="_blank" className='data-text link'>Website</a>
                </div>
              )}
              { companyData.linked_in_url && (
              <div>
                  <img src={linkIcon} className='icon-small' />
                  <a href={companyData.linked_in_url} target="_blank" className='data-text link'>LinkedIn</a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='company-profile__bio'>
          <p>{companyData.bio}</p>
        </div>
      </div>
    )
  }

  return (
    <div 
      id="companyProfileSidebar" 
    >
      { renderBody() }
    </div>
  );
};

export default CompanyProfileSidebar;
