import React from 'react';
import { Editor } from 'draft-js';
import 'draft-js/dist/Draft.css';

const CustomEditor = ({ 
  editorRef, 
  editorState, 
  onChange, 
  handleKeyCommand, 
  placeholder }) => (
  <Editor
    onMouseDown={e => e.preventDefault()}
    ref={editorRef}
    editorState={editorState}
    onChange={onChange}
    placeholder={placeholder || ''}
    handleKeyCommand={handleKeyCommand}
  />
);

export default CustomEditor;

