import React, { useState, useRef, useEffect } from 'react';
import APIClient from 'lib/APIClient';

const ContactActionIcon = ({ authToken, contactId, sequenceId }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  }
    
  const handleDelete = async () => {
    try {
      await APIClient.deleteSequenceContact(authToken, sequenceId, contactId);
      window.location.reload();
    } catch (error) {
      console.error('Error updating step settings:', error);
    }
  }

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  }

  return (
    <div className="action-container" ref={tooltipRef}>
      <div className="action-ellipsis" onClick={handleClick}>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>

      {showTooltip && (
        <div className="tooltip" onClick={handleDelete}>
          Delete contact
        </div>
      )}
    </div>
  );
}

export default ContactActionIcon;
