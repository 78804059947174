import React, { useState } from 'react';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';

const StudentFilterDrawer = ({ paramName, existingSelectedOptions }) => {  
  const [displayStudents, toggleDisplayStudents] = useState(existingSelectedOptions); 
  
  instantiateLiveFormApply(displayStudents);
  
  return (
    <>
      <label className="checkbox">
        <input type="checkbox"
               name={paramName}
               value={displayStudents}
               checked={displayStudents}
               onChange={() => toggleDisplayStudents(!displayStudents)}
               autoComplete="off" 
        />

        Show only Students 
      </label>
    </>
  )
}

export default StudentFilterDrawer;
