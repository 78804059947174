import React from "react";

export default function InsufficientEmailCreditsNotice() {
  return (
    <div className='email-error__box'>
      <p className='email-error__text'>
        You are out of email credits for this month. Contact a customer success manager to purchase more.
      </p>
    </div>
  )
};
