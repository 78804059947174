import React, { useState, useEffect } from 'react';
import EmailEditor from '../textEditor/EmailEditor';
import APIClient from "lib/APIClient";
import { extractPlainText, normalizeWhiteSpace } from '../../helpers/text_editor_helper';
import { convertKeysToCamelCase } from '../../helpers/base_helper';

const StepEmailTemplateEditor = ({
  initialSubject,
  initialBody,
  initialHTMLBody,
  emailTemplateId,
  sequenceId,
  selectedStepId,
  authToken,
  setCurrentSteps,
  setSelectedStep,
}) => {
  const [subject, setSubject] = useState(initialSubject);
  const [body, setBody] = useState(initialBody || '');
  const [htmlBody, setHtmlBody] = useState(initialHTMLBody || '');

  useEffect(() => {
    setSubject(initialSubject);
  }, [initialSubject]);

  useEffect(() => {
    setBody(initialBody);
  }, [initialBody]);

  const updateBody = (bodyObject) => {
    const normalizedHtmlBody = normalizeWhiteSpace(bodyObject.htmlBody);
    
    setBody(bodyObject.body);
    setHtmlBody(normalizedHtmlBody);
  };

  const saveEmailTemplate = async () => {
    const plainTextBody = extractPlainText(body);
    const response = await APIClient.saveEmailTemplate(
      authToken,
      emailTemplateId,
      body,
      htmlBody,
      plainTextBody,
      subject
    );
    if (!response.ok) throw new Error('Failed to save email template');
    return response.json();
  };

  const saveCRMStep = async (newTemplateId) => {
    const response = await APIClient.saveCRMStep(
      authToken,
      selectedStepId,
      newTemplateId,
      sequenceId,
    );
    if (!response.ok) throw new Error('Failed to save CRM step');
    return response.json();
  };

  const fetchAndSetSteps = async () => {
    const stepsResponse = await APIClient.requestSequenceSteps(authToken, sequenceId);
    const camelCaseSteps = convertKeysToCamelCase(stepsResponse);
    setCurrentSteps(camelCaseSteps);
    return camelCaseSteps;
  };

  const handleSave = async () => {
    try {
      const { id: newTemplateId } = await saveEmailTemplate();
      const { id: stepId } = await saveCRMStep(newTemplateId);
      const updatedSteps = await fetchAndSetSteps();
      const lastTouchedStep = updatedSteps.find((step) => step.id === stepId);
      setSelectedStep(lastTouchedStep);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className='text-editor'>
      <br />
      <EmailEditor
        body={body}
        setBody={updateBody}
        subject={subject}
        handleSubjectChange={(e) => setSubject(e.target.value)}
        handleSave={handleSave}
      />
      <br />
    </div>
  );
};

export default StepEmailTemplateEditor;
