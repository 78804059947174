import React from 'react';
import { CompositeDecorator } from 'draft-js';

const TextDecorators = () => {
  // Links

  const linkStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  };

  const LinkComponent = (props) => {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return <a href={url} title={url} style={{ color: 'blue', textDecoration: 'underline' }}>{props.children}</a>;
  };

  // Injected Placeholders

  const injectedPlaceholderStrategy = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();
    const regex = /\{\{\s[^{}]+\s\}\}/g;

    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      const end = start + matchArr[0].length;
      callback(start, end);
    }
  }

  const InjectedPlaceholderComponent = (props) => (
    <span style={{ color: '#5B4EFF', backgroundColor: '#F3F4F8' }}>
      {props.children}
    </span>
  );

  const compositeDecorator = new CompositeDecorator([
    {
      strategy: linkStrategy,
      component: LinkComponent
    },
    {
      strategy: injectedPlaceholderStrategy,
      component: InjectedPlaceholderComponent
    }
  ]);

  return compositeDecorator;
}

export default TextDecorators;
